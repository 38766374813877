import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Snackbar from '@material-ui/core/Snackbar';
import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import React, { useState } from 'react';
import './Footer.css';

function Footer() {
  const [snackOpen, setSnackOpen] = useState(true)
  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <React.Fragment>
      <footer className="footer">
        <List>
          <b>Connect</b>
          <a
            className="footerLink"
            href="https://www.facebook.com/infermath"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItem button>
              <FacebookIcon className="icon" />
              <ListItemText primary="Facebook" />
            </ListItem>
          </a>
          <a
            className="footerLink"
            href="https://www.linkedin.com/in/paweł-dudko-9a781558/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItem button>
              <LinkedInIcon className="icon" />
              <ListItemText primary="LinkedIn" />
            </ListItem>
          </a>
          <a
            className="footerLink"
            href="mailto:pawel.dudko@infermath.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItem button>
              <EmailIcon className="icon" />
              <ListItemText primary="Email" />
            </ListItem>
          </a>
        </List>
        <List>
          <b>Courses</b>
          <a
            className="footerLink"
            href="https://www.youtube.com/channel/UCe8Z-y5sY3CEMeH3iQA__lQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItem button>
              <YouTubeIcon className="icon" />
              <ListItemText primary="YouTube" />
            </ListItem>
          </a>
          <a
            className="footerLink"
            href="https://github.com/infermath"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItem button>
              <GitHubIcon className="icon" />
              <ListItemText primary="GitHub" />
            </ListItem>
          </a>

          <a
            className="footerLink"
            href="https://www.udemy.com/course/probability-in-r-discrete-random-variables/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItem button>
              <ListItemText primary="Udemy" />
            </ListItem>
          </a>
        </List>

        <List>
          <b>Recommended</b>
          <a
            className="footerLink"
            href="https://www.quantfin.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItem button>
              <ListItemText primary="QuantFin" />
            </ListItem>
          </a>
          <a
            className="footerLink"
            href="https://paweldudko.pl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItem button>
              <ListItemText primary="Quant blog" />
            </ListItem>
          </a>
          <a
            className="footerLink"
            href="https://www.3blue1brown.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItem button>
              <ListItemText primary="3Blue1Brown" />
            </ListItem>
          </a>
        </List>

        <List>
          <b>Legal</b>
          <ListItem button onClick={() => {
            setSnackOpen(false)
            setDialogOpen(true)
          }}>
            <ListItemText primary="Cookies policy" />
          </ListItem>
          <ListItem>
            <ListItemText>&copy; Paweł Dudko 2021</ListItemText>
          </ListItem>
        </List>
      </footer>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackOpen}
        className="cookieBar"
        message={<span id="message-id">This website is using cookies for Google Analytics</span>}
        action={[
          <Button
            key="policy"
            color="primary"
            size="small"
            onClick={() => {
              setSnackOpen(false)
              setDialogOpen(true)
            }}
          >
            Cookies policy
            </Button>,
          <Button
            key="ok"
            size="small"
            onClick={() => setSnackOpen(false)}
          >
            <p style={{ color: 'green' }}>OK</p>
          </Button>,
        ]}
      />

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        scroll={'paper'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Cookie policy"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>This cookie policy (&quot;Policy&quot;) describes what cookies are and how Website Operator (&quot;Website Operator&quot;, &quot;we&quot;, &quot;us&quot; or &quot;our&quot;) uses them on the <a target="_blank" rel="noopener noreferrer" href="https://infermath.com">infermath.com</a> website and any of its products or services (collectively, &quot;Website&quot; or &quot;Services&quot;).</p>
            <p>You should read this Policy so you can understand what type of cookies we use, the information we collect using cookies and how that information is used. It also describes the choices available to you regarding accepting or declining the use of cookies.</p>
            <h2>What are cookies?</h2>
            <p>Cookies are small pieces of data stored in text files that are saved on your computer or other devices when websites are loaded in a browser. They are widely used to remember you and your preferences, either for a single visit (through a &quot;session cookie&quot;) or for multiple repeat visits (using a &quot;persistent cookie&quot;).</p>
            <p>Session cookies are temporary cookies that are used during the course of your visit to the Website, and they expire when you close the web browser.</p>
            <p>Persistent cookies are used to remember your preferences within our Website and remain on your desktop or mobile device even after you close your browser or restart your computer. They ensure a consistent and efficient experience for you while visiting our Website or using our Services.</p>
            <p>Cookies may be set by the Website (&quot;first-party cookies&quot;), or by third parties, such as those who serve content or provide advertising or analytics services on the website (&quot;third party cookies&quot;). These third parties can recognize you when you visit our website and also when you visit certain other websites.</p>
            <h2>What type of cookies do we use?</h2>
            <h3>Analytical cookies</h3>
            <p>These cookies enable us and third-party services to collect aggregated data for statistical purposes on how our visitors use the Website. These cookies do not contain personal information such as names and email addresses and are used to help us improve your user experience of the Website.</p>
            <h2>What are your cookie options?</h2>
            <p>If you don't like the idea of cookies or certain types of cookies, you can change your browser's settings to delete cookies that have already been set and to not accept new cookies. To learn more about how to do this or to learn more about cookies, visit <a target="_blank" rel="noopener noreferrer" href="https://www.internetcookies.org">internetcookies.org</a></p>
            <p>Please note, however, that if you delete cookies or do not accept them, you might not be able to use all of the features our Website and Services offer.</p>
            <h2>Changes and amendments</h2>
            <p>We reserve the right to modify this Policy relating to the Website or Services at any time, effective upon posting of an updated version of this Policy on the Website. When we do we will revise the updated date at the bottom of this page. Continued use of the Website after any such changes shall constitute your consent to such changes. Policy was created with <a target="_blank" rel="noopener noreferrer" title="Create cookie policy" href="https://www.websitepolicies.com/blog/sample-cookie-policy-template">WebsitePolicies</a>.</p>
            <h2>Acceptance of this policy</h2>
            <p>You acknowledge that you have read this Policy and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to use or access the Website and its Services.</p>
            <h2>Contacting us</h2>
            <p>If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to our use of cookies, you may send an email to pa&#119;el&#46;&#100;u&#100;&#107;o&#64;&#105;&#110;fe&#114;math.&#99;&#111;&#109;</p>
            <p>This document was last updated on April 17, 2020</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Footer;
