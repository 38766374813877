import React from 'react';
import './App.css';
import Footer from './footer';
import logo from './img/logo.png';
import TutorialTree from './tutorialTree';

function App() {
  return (
    <div className="App">
      <div className="App-header">
        <header>
          <div>
            <img 
              src={logo} 
              className="logo" 
              alt="logo" 
            />
            <p className="green">
              What would you like to learn today?
            </p>
          </div>
        </header>
        
        <main>
          <TutorialTree />
        </main>
      </div >        

      <Footer />
    </div>
  );
}

export default App;
