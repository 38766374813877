import 'katex/dist/katex.min.css';
import React, { useEffect, useRef, useState, RefObject } from 'react';
import { BlockMath } from 'react-katex';
import Exercise from '../exercise';
import Tutorial from '../tutorial';
import pythonLogo from '../img/python.png';
import rLogo from '../img/Rlogo.png';
import './TutorialTree.css';
import { tree } from '../utils/tree';
import NodeButton from '../nodeButton';
import { TutorialNode } from '../tutorial/Tutorial';
import { ExerciseNode } from '../exercise/Exercise';

const scrollToRef = (ref: RefObject<HTMLDivElement>) => {
  if(ref.current !== null){
    window.scrollTo({
      top: ref.current.offsetTop,
      left: 0,
      behavior: 'smooth'
    });
  }
}

export type NodeState ={
  top? : number
  middle? : number
  bottom? : number
}

export type ArticleNode ={
  node: string
  type: 'video' | 'exercise' | 'article'
  title: string
  text:{
    title?: string
    text: string
  }[]
}

function TutorialTree() {
  const [node, setNode] = useState<NodeState>({})   
  const chosenNode = typeof node.top !== 'undefined' && typeof node.middle !== 'undefined' && typeof node.bottom !== 'undefined' 
    ? tree[node.top].branches[node.middle].branches[node.bottom] : undefined
 
  const topRef = useRef(null)
  const middleRef = useRef(null)
  const bottomRef = useRef(null)

  useEffect(()=>{
    scrollToRef(topRef)
  } ,[node.top])

  useEffect(()=>{
    scrollToRef(middleRef)
  } ,[node.middle])

  useEffect(() => {
    scrollToRef(bottomRef)
  }, [node.bottom]);

  return (
    <div>
      <div className="row">
        {tree.map((branch, index) =>
          <NodeButton 
            key={index}
            index={index}
            level={'top'}
            node={node}
            onClick={setNode}
          >
            <img 
              src={index ? pythonLogo : rLogo} 
              className="logo" 
              alt={index ? "python" : "r"} 
            />
            <p>{branch.node}</p>
          </NodeButton>
        )}
      </div>

      <div ref={topRef}>
        {typeof node.top !== 'undefined' && 
          <div className="row fadeIn">
            {tree[node.top].branches.map((branch, index) =>
              <NodeButton 
                key={index}
                index={index}
                level={'middle'}
                node={node}
                onClick={setNode}
              >
                {branch.node}
              </NodeButton>
            )}
          </div>
        }
      </div>

      <div ref={middleRef}>
        {typeof node.top !== 'undefined' && typeof node.middle !== 'undefined' && 
          <div className="row fadeIn" >
            {tree[node.top].branches[node.middle].branches.map((branch, index) =>
              <NodeButton 
                key={index}
                index={index}
                level={'bottom'}
                node={node}
                onClick={setNode}
              >
                <p>{branch.node}</p>

                {'highlight' in branch && <code>{branch.highlight}</code>}

                <p className="green">
                  {'mathHighlight' in branch && <BlockMath math={branch.mathHighlight}/>}
                </p>
              </NodeButton>
            )}
          </div>
        }
      </div>

      <div ref={bottomRef}>
        {chosenNode &&
          <div className="fadeIn row">
            {chosenNode.type === "video" && <Tutorial chosenNode={chosenNode as TutorialNode} />}
            {chosenNode.type === "exercise" && <Exercise chosenNode={chosenNode as ExerciseNode}/>}
            {chosenNode.type === "article" &&
              <div className="article">
                <h2>{(chosenNode as ArticleNode).title}</h2>
                {(chosenNode as ArticleNode).text.map(paragraph =>
                  <p key={paragraph.title}>
                    {'title' in paragraph && <h3>{paragraph.title}</h3>}
                    {paragraph.text}
                  </p>
                )}
              </div>
            }
          </div>
        }
      </div>
    </div>
  );
}

export default TutorialTree;
