import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import MuiAlert from '@material-ui/lab/Alert';
import React, { ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { BlockMath } from 'react-katex';
import './Exercise.css';

const scrollToRef = (ref: RefObject<HTMLDivElement>) => {
  if(ref.current !== null){
    window.scrollTo({
      top: ref.current.offsetTop,
      left: 0,
      behavior: 'smooth'
    });
  }
}

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// function Alert() {
//   return <MuiAlert elevation={6} variant="filled" />;
// }

export type ExerciseNode = {
  node: string
  type: 'video' | 'exercise' | 'article'
  highlight: string
  text: string[]
  solution: {
    text: string[]
    link?: {
      href: string
      text: string 
    }
    math?: string[]
    code?: string[]
  }
  answers:{
    type: string,
    right: number,
    text: string
  }[]
}

function Exercise(props: {chosenNode: ExerciseNode}) {
  const { chosenNode } = props
  const [showSolution, setShowSolution] = useState(false)
  const [correctOpen, setCorrectOpen] = useState(false)
  const [wrongOpen, setWrongOpen] = useState(false)
  const [answers, setAnswers] = useState<string[]>([])
  const solutionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    scrollToRef(solutionRef)
  }, [showSolution]);

  return (
  <React.Fragment>
    <div className="article">
      {chosenNode.text.map((line, i) => <p key={i}>{line}</p>)}

      <div ref={solutionRef} ></div>

      {showSolution ? 
        <div className="fadeIn" >
          <h2>Solution:</h2>
          {chosenNode.solution.text.map((line, i) => <p key={i}>{line}</p>)}
          {chosenNode.solution.link && <a href={chosenNode.solution.link.href} target="_blank" rel="noopener noreferrer" className="App-link">{chosenNode.solution.link.text}</a>}
          {chosenNode.solution.math && chosenNode.solution.math.map(block =><BlockMath math={block} />)}
          {chosenNode.solution.code &&
            <div>
              <h2>Code:</h2>
              <code>
                {chosenNode.solution.code.map((line, i) => <p key={i}>{line}</p>)}
              </code>
            </div>
          }
        </div>
        :
        <div>
          <h2>Your answer</h2>
          <p>Provide the answer in decimal numbers, rounded to two decimal places if necessary</p>
          <div className="row">
            {chosenNode.answers.map((answer,i) =>
              <div>
                <TextField
                  key={i} 
                  InputProps={{style: {color:'white', fontSize: 32}}}
                  InputLabelProps={{style: {color:'white', fontSize: 32}}} 
                  label={answer.text}
                  onChange={(event)=>setAnswers([...answers.slice(0,i), event.target.value, ...answers.slice(i+1)])}
                />
                <button className="smallButton green"
                  onClick={() => chosenNode.answers[i].right === parseFloat(answers[i]) ? setCorrectOpen(true) : setWrongOpen(true)}
                >
                  Check answer
                </button>
              </div>
            )}
            </div>
          <button className="smallButton green" onClick={() => setShowSolution(true)}>
            Show solution
          </button>
        </div>
      }
    </div>

    <Snackbar open={correctOpen} autoHideDuration={6000} onClose={() => setCorrectOpen(false)}>
        <Alert onClose={() => setCorrectOpen(false)} severity="success">
          Correct!
        </Alert>
      </Snackbar>

      <Snackbar open={wrongOpen} autoHideDuration={6000} onClose={() => setWrongOpen(false)}>
        <Alert onClose={() => setWrongOpen(false)} severity="error">
          Not quite! try again
        </Alert>
      </Snackbar>
  </React.Fragment>
  );
}

export default Exercise;
