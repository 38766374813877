import React, { ReactNode } from 'react';
import './NodeButton.css';
import { NodeState } from '../tutorialTree/TutorialTree'

type ButtonProps = {
    index: number
    level: 'top' | 'middle' | 'bottom'
    node: NodeState
    onClick: (node: NodeState) => void
    children: ReactNode
}

function NodeButton(props : ButtonProps) {
    const { level, node, index } = props
    let buttonClass = 'nodeButton '
    buttonClass += {
        top: 'topButton',
        middle: 'middleButton',
        bottom: 'bottomButton'
    }[level]

    // highlight pressed button
    if(node[level]===index){
        buttonClass += ' green'
    }

    const newNode = {
        top: {
            top: index
        },
        middle: {
            top: node.top,
            middle: index
        },
        bottom: {
            top: node.top,
            middle: node.middle,
            bottom: index
        }
    }[level]
    
    const onClick = ()=> {
        props.onClick(newNode)
    }

    return (
        <button
            className={buttonClass}
            onClick={onClick}
        >
            {props.children}
        </button>
    );
}

export default NodeButton;
