import React from 'react';
import { BlockMath } from 'react-katex';
import './Tutorial.css';

export type TutorialNode = {
  node: string
  type: 'video' | 'exercise' | 'article'
  highlight?: string
  mathHighlight?: string
  url: string
  text?: string
  code?: string[]
  math?: string[]
  link?: {
    href: string
    text: string 
  }
  commands?: {
    code: string
    text: string
  }[] 
}

function Tutorial(props: {chosenNode: TutorialNode}) {
  const { chosenNode } = props
  if('link' in chosenNode){
    console.log(chosenNode.link)
  }

  return (
    <div className="tutorial"> 
      <iframe
        title="0"
        className="video"
        src={chosenNode.url}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen></iframe>
      <div className="description">
        {chosenNode.link && 
          <a href={chosenNode.link.href} className="App-link" target="_blank" rel="noopener noreferrer">
            {chosenNode.link.text}
          </a>
        }

        {'text' in chosenNode && <p>{chosenNode.text}</p>}

        {chosenNode.math &&
          <div>
            <h2>Math:</h2>
            <p className="green math">{chosenNode.math.map(block =><BlockMath math={block} />)}</p>
          </div>
        }

        {chosenNode.commands &&
          <div>
            <h2>Commands and functions:</h2>
            {chosenNode.commands.map(command =>
              <p key={command.code}><code>{command.code}</code> - {command.text}</p>
            )}
          </div>
        }

        {chosenNode.code && <div>
          <h2>Code:</h2>
          {chosenNode.code.map(command =>
            <p key={command}><code>{command}</code></p>
          )}
        </div>}
      </div>
    </div>
  );
}

export default Tutorial;
