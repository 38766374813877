import { TutorialNode } from '../tutorial/Tutorial'
import { ExerciseNode } from '../exercise/Exercise'
import { ArticleNode} from '../tutorialTree/TutorialTree'

export type Tree = {
  node: string
  branches: {
    node: string
    branches: (TutorialNode | ExerciseNode | ArticleNode)[]
  }[]
}[]

export const tree: Tree = [
  {
    node: "Probability",
    branches: [
      {
        node: "Bernoulli distribution",
        branches: [{
          node: "Fair coin toss",
          type: "video",
          url: "https://www.youtube.com/embed/6ohXiWukQhA",
          link: { href: "https://cran.r-project.org/bin/windows/base/", text: "Download R" },
          commands: [
            { code: "#", text: "comments out code" },
            { code: "runif(n)", text: "generates n draws from standard uniform distribution" },
            { code: "round(x)", text: "rounds x to the nearest integer" },
            { code: "sum(v)", text: "sums elements of vector v" },
            { code: "mean(v)", text: "averages elements of vector v" },
            { code: "v [ i : j ]", text: "looks at elements from vector v indexed from i to j " }
          ],
          code: [
            "n = 10^6",
            "r = round(runif(n))",
            "mean(r[1:10000])",
          ],
          highlight: "round(runif(n))"
        }, {
          node: "Bernoulli",
          type: "video",
          url: "https://www.youtube.com/embed/FmlvQtY9xxM",
          text: "In this video we generate a Bernoulli random variables using R. We familiarize ourselves with boolean variables and logical conditions in R.",
          commands: [
            { code: "a < b", text: "returns TRUE if a is less than b and FALSE otherwise" },
            { code: "rbinom(n, 1, p)", text: "generates n draws from bernoulli distribution distribution with parameter p" },
          ],
          code: [
            "n = 10^6",
            "r = runif(n) < p",
            "mean(r)",
            "q = rbinom(n,1,p)",
          ],
          highlight: "rbinom(n,1,p)",
        }, {
          node: "Exercise",
          type: "exercise",
          text: ['Imagine you have a stick and break it in two points.',
            'What is the probability that you can build a triangle out of resulting three pieces?',
            'In R write a function that draws two independent numbers from uniform distribution and rerurns TRUE if they correspond to a division of [0,1] interval that enables to build a triangle and FALSE otherwise.',
            'Using this function simulate a vector of 10000 observations and calculate what is the the percentage of TRUE observations.',
            'Does it match your probability estimation?'
          ],
          highlight: 'tri=function()',
          answers:[{
            type: 'probability',
            right: 0.25,
            text: 'Probability'
          }],
          solution: {
            text: [
              'There is a nice geometric solution to that problem',
              'You can buld a triangle from 3 pieces only if none is longer then other two',
              'Each division of the stick can be mapped onto a point of equilatteral triangle',
              'Only the middle quarter of the triange corresponds to division which satisfies that condition',
            ],
            link: { href: "https://www.cut-the-knot.org/Curriculum/Probability/TriProbability.shtml", text: "Visualisation" },
            code: [
              'triangle = function()',
              ' {',
              '#draw two numbers from uniform',
              'x1 = runif(1)',
              'x2 = runif(1)',
              '',
              '#put them in order',
              'y1 = min(x1,x2)',
              'y2 = max(x1,x2)',
              '',
              '#check if none of the pieces is longer than other two (triangle condition)',
              'result = TRUE',
              'if (max(y1, y2-y1, 1-y2) > 0.5) result = FALSE',
              'return(result)',
              '}',
              '',
              '#use function for simulation',
              'n = 10000',
              'sum = 0',
              'for(k in 1:n){',
              'sum = sum + triangle()',
              '}',
              '',
              'probability = sum/n',
            ],
          }
        }]
      }, {
        node: "Binomial distribution",
        branches: [{
          node: "Intro",
          type: "video",
          url: "https://www.youtube.com/embed/6uS4CYTnaB0",
          text: "We define binomial distribution and generate it using R. We familiarize ourselves with histogram in R. ",
          commands: [
            { code: "hist(v)", text: "represents data from vector v in a histogram" },
            { code: "rbinom(n, m, p)", text: "generates n draws from binomial distribution distribution with parameters m and p " },
          ],
          code: [
            "p = 0.3",
            "n = 5",
            "size = 10000",
            "r = rbinom(size, n, p)",
            "hist(r)",
          ],
          highlight: 'rbinom(m,n,p)'
        }, {
          node: "Theory",
          type: "video",
          url: "https://www.youtube.com/embed/9hZ5voL-RuE",
          text: "In this episode we derive the probability of each outcome in binomial distribution. We show the probabilities add up to 1 using set theory, polynomials and Pascal's triangle. ",
          mathHighlight:'(p+q)^n',
          math: ['(p+q)^n = \\sum_{k=0}^n {n \\choose k} p^k q^{n-k}', '\\binom{n}{k} = \\frac{n!}{k!(n-k)!}']
        }, {
          node: "Simulation",
          type: "video",
          url: "https://www.youtube.com/embed/AqamWKkoV7E",
          text: "In this video we are comparing the probability distribution of binomial random variables to simulation in R. We learn how to generate vectors and matrices, use the for loop and bar plot in R. ",
          commands: [
            { code: "1:n", text: "generates a vector of n integers " },
            { code: "matrix(nrow = n, ncol = m)", text: "generates empty matrix of n rows and m columns" },
            { code: "for(i in v){ do }", text: "repeats do command for i taking all the values from vector v" },
          ],
          code: [
            'p = 0.3',
            'n = 5',
            'size = 10000',
            'r = rbinom(size,n,p)',
            "x = 0:n",
            "m = matrix(nrow = 2, ncol = n+1)",
            "for(k in x){",
            "m[1,k+1] = choose(n,k)*p^k*(1-p)^(n-k)",
            "m[2,k+1] = sum(r==k)/size",
            "}",
            'barplot(m,names.arg = x,col = c("red","blue"),beside=T)'
          ],
          highlight: 'matrix(nrow,ncol)'
        }, {
          node: "Exercise",
          type: "exercise",
          text: [
            'What is the probability that while drawing 2 out of n numbers we will get the first and the last number?',
            'In R build a matrix with 2 colums and 10000 rows, where each row stores 2 different numbers drawn from n.',
            'Calculate the percentage of rows that store 1 and n.',
            'Does it match with your probability calculation?',
            'Assume n=5 for the calculation',
          ],
          highlight:'k=function(n)',
          answers:[{
            type: 'probability',
            right: 0.1,
            text: 'Probability (n=5)'
          }],
          solution: {
            text: [
              "There are n choose 2 possible pairs",
              'We are looking for pair {1,n}',
              'Probability is 1/(n choose 2)',
              'For n=5 it is eqal to 1/10',
              'Function k(n) is defined to simplify the code.'
            ],
            code: [
              '#define a function that draws number from 1 to n',
              'k = function(n)',
              '{',
              'result = round(runif(1)*n+0.5)',
              '}',
              '#define parameters',
              'n = 5',
              'm = 10000',
              '#build matrix',
              'x = matrix(nrow = m, ncol =2)',
              'for(i in 1:m)',
              '{',
              'x[i,1] = k(n)',
              'x[i,2] = k(n)',
              'while (x[i,1] == x[i,2]) x[i,2] = k(n)',
              '}',
              '#count cases when first and last integers are drawn',
              'count = 0',
              'for(i in 1:m)',
              '{',
              'if ((x[i,1] == 1 && x[i,2] == n) || (x[i,1] == n && x[i,2] == 1)) count = count +1',
              '}',
              'probability = count/m',
            ]
          }
        }],
      }, {
        node: "Geometric distribution",
        branches: [{
          node: "Intro",
          type: "video",
          url: "https://www.youtube.com/embed/k3N0twVPyew",
          text: "We define geometric distribution and draw random variables from it in R. We familiarize ourselves with while loop and scientific notation.",
          commands: [
            { code: "while(bool){ do }", text: "repeats do command while bool logical condition is TRUE" },
            { code: "rgeom(n, p)", text: "generates n draws from geometric distribution with parameter p " },
            { code: "1+e6", text: "short notation for 10 to power 6 " }
          ],
          code: [
            "i = 1",
            "p = 0.3",
            "while(runif(1)<p){i=i+1}",
            "rgeom(1,p)"
          ],
          highlight: "rgeom(1,p)",
        }, {
          node: "Theory",
          type: "video",
          url: "https://www.youtube.com/embed/74kUoqGgA1s",
          text: "We derive probability distribution of the geometric random variables and learn about geometric series. We encounter cumulative distribution function",
          mathHighlight: '1+q+q^2+...',
          math:['1+q+q^2+...=\\frac{1}{1-q}', '1+q+q^2+...+q^n=\\frac{1-q^n}{1-q}']
        }, {
          node: "Simulation",
          type: "video",
          url: "https://www.youtube.com/embed/1mbAPlILTfQ",
          text: "We want to increase to infinity the maximum value coming from geometric distribution. On our way we encounter NAs and machine epsilon but don't give up. ",
          commands: [
            { code: "max(v)", text: "returns maximal element in data set v" },
            { code: "na.rm=TRUE", text: "optional function argument making it ignore NA values" },
            { code: ".Machine$Double.eps", text: "returns lowest in absolute value number of type double" }
          ],
          code: [
            "n = 1e+4",
            "p = 1e-5",
            "r = rgeom(n,p)",
            "max(r, na.rm=TRUE)",
            "",
            "# machine epsilon",
            ".Machine$double.eps",
          ],
          highlight: "rgeom(n,p)",
        }, {
          node: "Exercise",
          type: "exercise",
          text: [
            'Expected value of variable X (EX) is defined as sum of all possible values of X multiplied by their probabilities.',
            'So for variable X from geometric distribution it will be: EX = 0*P(X=0)+1*P(X=1)+2*P(X=2)+ ...',
            'It is interpreted as how long on average we need to wait for the successful trial.',
            'What is the expected value for variable coming from geometric distribution?',
            'Write a function in R that generates 10000 observations of variable X and calculate their average value.',
            'Does it match your estimation of the expected value?',
            "Assume p = 1/3",
          ],
          highlight: 'E(X)',
          answers:[{
            type: 'price',
            right: 2,
            text: 'Expected value'
          }],
          solution: {
            text: [
              'There are two methods.',
              'First we will use the definition of EX and the formula 1+q+...=1/(1-q) multiple times',
              'Derivation using the first method below.',
              'Alternatively we could calculate EX as follows:',
              'If the first trial is successful we stop and X = 0',
              'Otherwise we try again and EX is increased by 1',
              'This gives us the following equation:',
              'EX = p*0 + q*(EX+1)',
              'Solving for EX gives us the same result.'
            ],
            math:[
              'EX = 0*P(X=0)+1*P(X=1)+2*P(X=2)+ ...',
              'EX = 0*p + pq + 2pq^2 +...',
              'EX = p(q + 2q^2 +...)',
              'EX = p(1 + 2q + 3q^2 +...) - p(1 + q + q^2+ ... ) ',
              'EX = p((1 + q + q^2 +...) + q(1 + q + q^2 +...) + q^2(1 + q + q^2 +...) + ...) - \\frac{p}{1-q}',
              'EX = p(\\frac{1}{1-q} + \\frac{q}{1-q}+\\frac{q^2}{1-q}+...) - 1',
              'EX = \\frac{p}{1-q}(1 + q + q^2+...) - 1 ',
              'EX = \\frac{p}{p}\\frac{1}{1-q} - 1',
              'EX = 1/p - 1',
            ],
            code: [
              'mean(rgeom(10000,1/3))'
            ]
          },
        }, {
          node: "Exercise",
          type: "exercise",
          text: [
            'Random variable X is defined as number of unsuccessful Bernoulli trials before the first two successful trial in a row' ,
            'What is the expected value of X? Assume p-probability of success in single trial = 1/2',
            'Write a function in R that takes argument p-probability of success and generates variable X', 
            'Generate 10000 observations of variable X and calculate their average value.',
            'Does it match your estimation of the expected value?',
            'Hint: for R function you can use recursion (function calling itself)',
          ],
          highlight: 'x=function(p)',
          answers:[{
            type: 'price',
            right: 4,
            text: 'Expected value'
          }],
          solution: {
            text: [
              'Each time we get unsuccesful trial we need to start from beginning',
              'If the first trial is unsucessful the EX is increased by 1 (unsuccessful trial)', 
              'If it is successful we try again: if its successful the game is over and X = 0, otherwise EX is increased by 2',
              'This gives us equation:',
              'EX = 0.5*(EX+1) + 0.25*0 + 0.25*(EX+2)',
              'Solving for EX gives 4'
            ],
            code: [
              '#define function',
              'x = function(p)',
              '{',
              'result=0',
              '#We make first Bernoulli trial',
              'x0 = runif(1)',
              '#if its a Fail we increse number of unsucessful trials by 1 and try again',
              'if (x0>p) result = result+1+x(p)',
              'else{',
              '#Otherwise we look at second trial',
              'x1=runif(1)',
              '#If its a Fail we increse number of unsucessful trials by 2 and try again',
              'if(x1>p) result = result+2+x(p)',
              '#If both are successful we finish',
              'else result = 0',
              '}',
              'return(result)',
              '}',

              'sum = 0',
              'm = 10000',
              'p= 0.5',

              'for(i in 1:m){sum = sum + x(p)}',

              'print(sum/m)',
            ]
          },
        }],
      }, {
        node: "Borel-Cantelli lemma",
        branches: [{
          node: "Intro",
          type: "video",
          url: "https://www.youtube.com/embed/zHQ_kqYkK1o",
          text: "We create a function in R returning a result of Bernoulli trial and we use it in for loop to generate series of trials. We try to understand what does it mean for a series of events to happen infinitely many times.",
          commands: [
            { code: "f = function(x){return(x*x)}", text: "function syntax in R, f(3) returns square of 3 " },
            { code: "print(x)", text: "prints value of x in console" },
            { code: "flush.console()", text: "forces output display" },
            { code: "Sys.sleep(x)", text: "holds execution for x sec " },
          ],
          code: [
            '#defining function of k returning draws from Bernoulli(1/k) distribution',
            'x = function(k){',
            'return(runif(1)<1/k)',
            '}',
            '',
            '#running the loop',
            'for (k in 1:10){',
            'print(k)',
            'print(x(k))',
            'flush.console()',
            'Sys.sleep(1)',
            '}',
          ],
          highlight: 'runif(1)<1/k'
        }, {
          node: "Proof 1.",
          type: "video",
          url: "https://www.youtube.com/embed/p7dN3jIOXQI",
          text: "We derive probability distribution of the Borel-Cantelli random variables and learn about Borel-Cantelli series. We encounter cumulative distribution function",
          mathHighlight: '\\sum_{n=1}^{\\infty}P(E_{n}) < \\infty',
          math:[
            '\\textrm{Statement}',
            'E_1, E_2 ... - \\textrm{series of events}',
            '\\sum_{n=1}^{\\infty}P(E_{n}) < \\infty \\Rightarrow  P(\\bigcap_{n=1}^\\infty \\bigcup_{k=n}^\\infty E_k)=0', 
            '\\textrm{Proof}',
             "0 \\leq P(\\bigcap_{n=1}^{\\infty} \\bigcup_{k=n}^{\\infty} E_k ) \\leq P(\\bigcup_{k=n}^{\\infty} E_k) \\leq \\sum_{k=n}^{\\infty}P(E_k) ", 
            "\\lim_{n \\rightarrow \\infty}\\sum_{k=n}^{\\infty}P(E_k)=0 \\Leftrightarrow \\sum_{n=1}^{\\infty}P(E_n)<\\infty ", 
            "\\sum_{n=1}^{\\infty}P(E_n)<\\infty \\implies P(\\bigcap_{n=1}^{\\infty} \\bigcup_{k=n}^{\\infty} E_k) =0 "
          ]
        }, {
          node: "Proof 2.",
          type: "video",
          url: "https://www.youtube.com/embed/sfATJGELc0A",
          text: "We prove the second part of Borel-Cantelli lemma. We come across exponential function, complementary events, a monkey and William Shakespeare. ",
          mathHighlight: '\\sum_{n=1}^{\\infty}P(E_{n}) = \\infty',
          math:[
            '\\textrm{Statement}',
            'E_1, E_2 ... - \\textrm{independent events}',
            '\\sum_{n=1}^{\\infty}P(E_{n}) = \\infty \\Rightarrow P(\\bigcap_{n=1}^\\infty \\bigcup_{k=n}^\\infty E_k)=1', 
            '\\textrm{Proof}',
            'P(\\bigcap_{n=1}^{\\infty} \\bigcup_{k=n}^{\\infty} E_k)=1 \\Leftrightarrow P(\\bigcup_{n=1}^{\\infty} \\bigcap_{k=n}^{\\infty} E_k^c)=0',
            'P(\\bigcap_{k=n}^{\\infty} E_k^c) = \\prod_{k=n}^{\\infty} P(E_k^c) = \\prod_{k=n}^{\\infty} (1-P(E_k))',
            "\\leq \\prod_{k=n}^{\\infty} exp(-P(E_k))= exp(-\\sum_{k=n}^{\\infty}P(E_k)) ",
            "=exp(-\\infty)=0"
          ]
        }, {
          node: "Simulation",
          type: "video",
          url: "https://www.youtube.com/embed/GlcpJ8VgGgQ",
          text: "In the last episode of discrete random variables we use Borel-Cantelli lemma to generate infinite series of successful Bernoulli trials. As we approach infinity we turn to philosophy and music.",
          commands: [
            { code: ".Machine$integer.max", text: "returns maximal number od type integer" },
            { code: "sqrt(x)", text: "square root of x " },
            { code: "if(bool){do}", text: "executes do command if bool logical condition is TRUE " }
          ],
          code: [
            'max = .Machine$integer.max',
            'while (k < max){',
            'if(runif(1)<1/sqrt(k)){print(k)}',
            'flush.console()',
            'k = k+1',
            '}',
          ],
          highlight: "runif(1)<1/sqrt(k)"
        }, {
          node: "Exercise",
          type: "exercise",
          text: [
            'We have a box with certain amount of black and white marbles (at least one each).',
            'We draw one marble, write down the result and put it back to the box.',
            'After each draw we put one extra black marble to the box. ',
            'What is the probability we will be drawing white marble forever?',
            "Instead of adding one black marble let's add number of black marbles corresponding to the draw number.",
            'So after the first draw we add one black marble, after the second draw we add two, after the third we add three and so on.',
            'What is now the probability we will be drawing the white marble forever?',
          ],
          highlight:'1/f(n)',
          answers:[{
            type: 'probability',
            right: 0,
            text: 'one marble'
          },{
            type: 'probability',
            right: 1,
            text: 'n marbles'
          }],
          solution: {
            text: [
              'Without loss of generality we can assume we start with 1 white and 1 black marbles',
              'The probability of drawing white marble in nth draw is therefore 1/(n+1)',
              'Sum of probabilities 1/n is divergent (infinite) with n going to infinity',
              'Therefore according to Borel Cantelli lemma probability that we will be drawing white marble infinitely many times is 1',

              'In the second case we have 1 white vs 1+2+...+n = n(n+1)/2 black marbles',
              'The probability of drawing white marble in nth draw is therefore 1/(n(n+1)/2+1)',
              'Sum of probabilities 1/n^2 is convergent (finite) with n going to infinity',
              'Therefore according to Borel Cantelli lemma probability that we will be drawing white marble infinitely many times is 0',

              'Note that is is enough to know order of magnitude (e.g. n, n^2) to know if series is convergent ',
            ],
            link: { href: "https://en.wikipedia.org/wiki/Convergent_series", text: "More on series convergence" },
          }
        }],
      }
    ]
  }, {
    node: "Financial engineering",
    branches: [
      {
        node: "Fixed income",
        branches: [{
          node: "Intro",
          type: "video",
          url: "https://www.youtube.com/embed/NxkxjetUJ38",
          link: { href: "https://www.python.org/downloads/", text: "Download python" },
          text: "Downloading and launching python and idle. Introduction to object oriented programming: class, constructor, object, attribute, methods. Loan valuation: present value, future value and discount factor.",
          commands:[
            {code: 'class Loan:', text: 'defines class called Loan'},
            {code: 'def __init__(self,notional,redemption,ir):', text: 'class constructor definition'},
            {code: 'self.notional = notional', text: 'class atribute definition within constructor'},
            {code: 'def FV(self):', text: 'class method definition'},
          ],
          code: [
            'class Loan:',
            '   def __init__(self,notional,redemption,ir):',
            '       self.notional = notional',
            '       self.redemption = redemption',
            '       self.ir = ir',

            '   def FV(self):',
            '       return self.redemption - self.notional*(1+self.ir)',

            '   def PV(self):',
            '       return self.redemption/(1+self.ir) - self.notional',

            '   def DF(self):',
            '       return1/(1+self.ir)',
          ],
          highlight: 'class Loan:'
        }, {
          node: "Zero valuation",
          type: "video",
          url: "https://www.youtube.com/embed/n1MgAeD3iP8",
          text: "Yield to maturity is an interest rate that reflects annual return on a current price of a bond if held to maturity. Yield quotatation is equivalent to price quotation and is useful to compare different bonds. In this video we are calculating yield-to-maturity of a zero coupoun bond based on price and vice versa.",
          commands:[
            {code: "import math as m", text: 'imports standard python math library with alias m'},
            {code: "m.pow(base, power)", text: 'calls math library pow method for power calculation'}
          ],
          code: [
            "import math as m",

            'class Zero:',
            '   def __init__(self, maturity):',
            '       self.maturity  = maturity',

            '   def YTM(self, price):',
            '       return m.pow(100/price, 1/self.maturity) - 1',

            '   def Price(self, ytm):',
            '       return 100/m.pow(1+ytm,self.maturity)',
          ],
          highlight: 'class Zero:',
        }, {
          node: "Yield curve",
          type: "video",
          url: "https://www.youtube.com/embed/x88MzHD00Ag",
          link: { href: "https://github.com/infermath/pythontutorials", text: "Github repo" },
          text: "In the video we are building yield curve using matplotlib in python. We also explain how to price bonds based on the yield curve.",
          commands:[
            {code: 'plt.plot(maturities,yields)', text:'matplotlib plot method for creating plot object'},
            {code: "plt.show()", text:"displays the plot"},
            {code: 'range(0,4)', text: 'returns sequence of numbers 0 to 3'},
            {code: '[f(i) for i in someArray]', text: 'returns new array of same length as someArray, with each element transformed by function f'},
            {code: 'array.pop(2)', text: 'removes third element from an array and returns the element'},
            {code: 'np.interp(x,xArray,yArray)', text:'numpy interp method for linear interpolation'}
          ],
          code: `import zero as z
import matplotlib.pyplot as plt
import numpy as np

maturities = [2,5,10,30]
yields=[0.0249, 0.0246,0.0266,0.0306]

plt.plot(maturities,yields)
plt.show()

bonds=[z.Zero(i) for i in maturities]
prices=[bonds[i].Price(yields[i]) for i in range(0,4)]

maturities.pop(2)
marketYield = yields.pop(2)
marketPrice = prices.pop(2)

interpolatedPrice = np.interp(10,maturities,prices)
interpolatedYield = np.interp(10,maturities,yields)

priceFeromInterpolatedYield = bonds[2].Price(interpolatedYield)`.split(/\r?\n/),
          highlight: '.plot(mat,yield)',
        }, {
          node: "Exercise",
          type: "exercise",
          text: [
            'In Python write a class for bond with annual coupon payment.',
            'The class should have two attributes: maturity in years (assume integer) and coupon it pays (e.g. 0.05 for 5% coupon)',
            'The class should also have one method: calculating the price of the bond based on observed YTM, rounded to two decimal places.',
            'Use your class to calculate the prices below bonds:',
            '1 year bond paying 4% coupon with YTM 1%',
            '5 year bond paying 2% coupon with YTM 2%',
            '10 year bond paying 3% coupon with YTM 4%',
            '10 year bond paying 3% coupon with YTM 0%',
            'Do you see any patterns between YTM and bond prices?'
          ],
          highlight: 'class Bond:',
          answers:[{
            type: 'price',
            right: 102.97,
            text: '1y 4% c. 1% YTM'
          },{
            type: 'price',
            right: 100,
            text: '5y 2% c. 2% YTM'
          },{
            type: 'price',
            right: 91.89,
            text: '10y 3% c. 4% YTM'
          },{
            type: 'price',
            right: 130,
            text: '1y 3% c. 0% YTM'
          }],
          solution: {
            text: [
              'Apart from the discounted notional of the bond we need to include discounted coupons.',
              'We can do that using for loop over every year the coupon is paid.',
              'Prices of the bonds are:',
              '102.97 - the bond is trading above par, note that for 1 year bond the difference between bond price and notional (100) is close to 3% - the difference between coupon and YTM as there is just one coupon payment',
              '100.00 - the bond trades at par, the price is equal to the notional and YTM is equal to the coupon',
              '91.89 -the bond trades below par, the difference between price and notional can be approximated as (coupon - YTM) * maturity (10 in this case), it is not exact because of discounting.',
              '130.00 - the difference between bond price and notional is exactly (coupon - YTM) * maturity as the market interest rate is 0 and there is no discounting',
              'As you can see bond prices display so called price convexity: bond price is not linear but convex function of the yield',
              'Run the code below to see the graph between ytm and 10y bond price, do you see the convexity?'
            ],
            code: [
              'import math as m',
              'import matplotlib.pyplot as plt',

              "class Bond:",
              "   def __init__(self, maturity, coupon):",
              "       self.maturity  = maturity",
              "       self.coupon = coupon",
              
              "   def Price(self, ytm):",
              "       sum = 100/m.pow(1+ytm,self.maturity)",
              "       for i in range(1,self.maturity+1):",
              "           sum += 100*self.coupon/m.pow(1+ytm,i)",
              "       return round(sum,2)",
              
              "james=Bond(1,0.04)",
              'print(james.Price(0.01))',
              
              'jane=Bond(5,0.02)',
              'print(jane.Price(0.02))',

              'joe=Bond(10, 0.03)',
              'ir=range(10)',
              'prices=[joe.Price(i*0.01) for i in ir]',
              'print(prices)',
              'plt.plot(ir,prices)',
              'plt.show()',
            ]
          }
        }, {
          node: "Coupon bonds",
          type: "video",
          url: "https://www.youtube.com/embed/X5pF0GiR1JY",
          text: `Coupon bonds are the bonds that apart from notional at the end pay fixed coupon annually or semiannually until their maturity. 
                In this video we are going to create a bond class in python that will calculate the price as well as yield to maturity of a coupon bond. 
                To calculate the YTM we will use Newton-Rhapson numerical method for root finding.`,
          math: [
            'PV(r) = \\frac{100}{(1+r)^n} + \\sum_{i=1}^n \\frac{C}{(1+r)^i}',
            'PV(YTM) = Price',
            'PV(r) - Price = 0',
            'f(r) = \\frac{100}{(1+r)^n} + \\sum_{i=1}^n \\frac{C}{(1+r)^i} - Price = 0',
            '\\textrm{Newton-Rhapson method}',
            "f'(x_0)=\\lim_{x_1 \\to x_0} \\frac{f(x_1)-f(x_0)}{x_1-x_0}",
            "\\textrm{f-linear} \\Rightarrow f'(x_0) = \\frac{f(x_1)-f(x_0)}{x_1-x_0}",
            "f(x_1)=f'(x_0)(x_1-x_0)+f(x_0)=0",
            "x_1=x_0-\\frac{f(x_0)}{f'(x_0)}",
            '\\textrm{Application}',
            "f(r) = 100(1+r)^{-n} + \\sum_{i=1}^n C(1+r)^{-i} - Price",
            "f'(r) = -100n(1+r)^{-n-1} -\\sum_{i=1}^n iC(1+r)^{-i-1}",
          ],
          highlight: 'class Bond:',
          code: [
            'import math as m',
            'import matplotlib.pyplot as plt',
            '',
            'class Bond:',
            '    def __init__(self, maturity, coupon):',
            '        self.maturity = maturity',
            '        self.coupon = coupon #annual in %',
            ''    ,
            '    def pv(self, r):',
            '        sum = 100*m.pow(1+r, -self.maturity)',
            '        for i in range(1, self.maturity+1):',
            '            sum += self.coupon*m.pow(1+r, -i)',
            '        return round(sum,2)',
            ''    ,
            '    def pv_derivative(self, r):',
            '        sum = -self.maturity*100*m.pow(1+r, -self.maturity-1)',
            '        for i in range(1, self.maturity+1):',
            '            sum += -i*self.coupon*m.pow(1+r, -i-1)',
            '        return round(sum,2)',
            '',
            '    def pv_num_derivative(self, r):',
            '        return (self.pv(r+0.001)-self.pv(r))/0.001',
            '',
            '    def ytm(self, price):',
            '        x = 0',
            '        while(abs(round(self.pv(x)-price,2))>0):',
            '            x = x - (self.pv(x)-price)/self.pv_num_derivative(x)',
            '            # print(x)',
            '        return x' ,
            '',
            'bond = Bond(10, 4)',
            '# print(bond.pv(0.04))',
            '',
            'price = 91',
            'ir = range(10)',
            '',
            'ytm = bond.ytm(price)',
            'print(ytm)',
            'print(bond.pv(ytm))',
            '',
            'pvs = [bond.pv(i*0.01) -price for i in ir]',
            'tangent = [bond.pv(0)-price + i*0.01*bond.pv_derivative(0)  for i in ir]',
            'tangent4 = [bond.pv(0.04)-price + (i-4)*0.01*bond.pv_derivative(0.04)  for i in ir]',
            '',
            'plt.plot(ir, pvs)',
            'plt.plot(ir, tangent)',
            'plt.plot(ir, tangent4)',
            'plt.axvline(x=ytm*100)',
            'plt.grid(True)',
            'plt.show()',
          ]  
        }
      ],
      }, {
        node: "Articles",
        branches: [{
          node: "Crisis guide",
          type: "article",
          title: "Coronavirus crisis guide - how not to lose money (and health) during the crisis",
          text: [{
            text: `Recently one of my friends has approached me for financial advice.
            She heard that here in Poland they are going to print money and whether she should do something about it e.g. buy another safer currency like the US dollar.
            I thought that the explanaition I gave her might be interesting to more of you as we are entering a new period of financial crisis and insecurity.`
          }, {
            title: "So are they going to print money?",
            text: `Short answer: it's already happening.
            Let's first clarify who do we mean by they: the central banks.
            Polish central bank - National Bank of Poland (NBP) - has for the first time in its history started a bond buying programme, widely known as quantitative easing (QE).
            In fact it was one of the last central banks in (more or less) developed world to do so.
            It has already been done by the Fed (US), European Central Bank (eurozone), Bank of Japan etc. on a much larger scale pretty much constantly since the previous crisis of 2008.
            So if you are living in a developed economy chances are your central bank has been doing it before and will continue now at an increased pace.`
          }, {
            title: 'What are they doing with this money?',
            text: `Short answer: buing government bonds.
            Regardless where you are the chances are that your government is fighting the coronavirus.
            Also it's quite likely that they are fighting the recession caused by the lockdown (perhaps even harder than the virus itself) using multi-billion (enter your currency here) packages.
            Furthermore chances are that your government was already in debt before this crisis (50% of GDP? 100%? who gives more?).
            So where to get the money for the hard times from? This is where the central banks step in.
            Basically the central banks are providing unlimited credit to the governments via buying their bonds.`
          }, {
            title: 'How does it affect the economy?',
            text: `Short answer: interest rates go down.
            Firstly, those central banks who still had the chance to cut their interest rates (some had it already below 0%, hence could not do it any more) have probably already done so by now.
            Secondly, the bond buying programme of the central banks means that there is potentially unlimited demand for the public debt.
            As the demand for the bonds grows and the supply is stable the bond prices will increase - it's true for any market.
            Now here is a fact about bond market: bond prices are inversely related to the interest rates. i.e. when one increases the other one falls.
            Why is it so? Every bond pays certain fixed interest rate, for example 2% of a fixed notional (say 100).
            However, the price of the bond fluctuates - you can buy it cheaper or more expensive.
            If it is getting more expensive (as it is doing now) the return you will get from the bond is going to be lower.
            Why? Because in the end you get 2% of the notional anyway, but you paid more for the bond.
            So now the yield from the bonds is getting lower, which is also lowering the interest rate in the entire economy.`
          }, {
            title: 'What does it mean for me?',
            text: `Short answer: depends if you have debt or savings and what kind.
            If you have debt like mortgage that is based on a floating rate - index like libor, euribor etc. it will get cheaper for you.
            Why? Your interest rate index should also decrease and hence reduce your monthly payment.
            If you are thinking of paying the debt off - now it should be cheaper to do so.
            If your debt is based on a fixed rate - the value of your debt will stay the same.
            If you have cash you wanted to invest it in fixed income (bonds, saving accounts) you will now have have to accept lower rate of return.
            If you already have fixed income investments that you invested in before the crash - they are likely to be worth more now as they give you better return than currently possible.`
          }, {
            title: 'How about inflation? Money has to lose value if they print so much of it right?',
            text: `Short answer: right, but it's not that straightforward.
            Printing more money for sure is not increasing the value of it.
            However there are other factors in play here.
            First, crises usually slow down the inflation.
            As everything is in standstill overall demand for most products is falling, and so are their prices.
            In particular and especially important here is that the falling demand for oil.
            Combined with recent oil price war which drives the supply of oil up, it sent the price of oil to multi-year lows.
            Oil is a key product here because if it is cheap it is also cheaper to produce and transport goods - everything becomes cheaper.
            So while printing a lot of money is not going to help preserve its value, there are short term factors which are offsetting infation.
            Unless people lose faith in money overall and hyperinflation occurs it should remain stable and low.
            If you are still concerned that the inflation will go up a good idea for oyu might be investing in real estate.
            Real estate is a good hedge against inflation because You can adjust rent prices + now interest rates are low if you want to take mortgage for it.`
          }, {
            title: 'What about currency? Should I buy some safe haven currencies?',
            text: `Short answer: depends what is your view on how things will develop.
            It is natural to think that if your central bank is printing money your currency will lose value against others.
            However, as mentioned in the beginning right now pretty much every developed market central bank is printing money.
            That also includes those whose currencies are considered to be safe havens (like Japanese Yen or Swiss Franc).
            Secondly, if you have not bought them before the crisis now it might be a bit too late.
            Major crisis is already priced in i.e. reflected in the prices of currencies.
            If you think that it will get worse then it still makes sense for you to buy safe haven currencies.
            However, if things will get better from now on, safe haven currencies should be getting cheaper.`
          }, {
            title: 'So is there no negative effect of all this money printing at all? Why not print more then?',
            text: `Gold is a good benchmark against the value of currencies.
            Have you noticed the increase in gold price recently?
            As it usually happens during the crises it is gaining value.
            Main reason for that is that gold is of limited supply.
            In other words central banks cannot print as much gold as they want.
            In the past gold was used as a currency precisely because of this (and that it shines of course).
            If you look at the graph of the price of gold against any paper (so called fiat) currency and zoom out far enough you will see what I mean.`
          }, {
            title: 'So why are they doing this? It makes us all poorer, no?',
            text: `Short answer: to avoid full scale crisis
            It kind of worked during previous cirsis so we try it now as well.
            Despite great financial crash in 2008 the world economy regained its vitality relatively quickly compared to previous crises.
            It is largely thanks to the big money injections central banks made back then.
            The problem is that many economies got used to the drug and hardly stopped taking it even in recent relatively good years.
            As it happens with drug addicts when you feel bad you need more of the drug... much more then before.
            As it also happens with drugs it affects your health if you take it for too long.
            Each dose of this drug leaves us with more debt and the question is are we ever going to get healthy again.
            So in the end we might be just postponing the big crisis for the next generation (as we like doing in many areas).`
          }, {
            title: 'So what should I do now?',
            text: `In the time of a crisis my priority is be not to make but not to lose money.
            Cash is actually not a bad asset to have in such times.
            Even if it loses value it will be hard to notice compared to the drop in stock market or commodities.
            Even if you do have such a risky asset, chances are it has already taken a hit by now, so now it has more upside potential.
            So my general advice is to stay calm: in volatile times you can lose more money if you act in panic then if you don't do anything.`
          }, {
            text: `The virus crisis is a new situation and it is difficult to predict how it will develop.
            If you feel things will get much worse buying safe assests like safe haven currencies, bonds and gold should make you feel better.
            On the other hand if you think things will start to improve, now is the opportunity to buy some riskier assets cheaper.
            But you don't need to rush into it. Interest rates tend to fall sharply and rise slowly as does the stock market, it is the nature of crises.
            If things improve they will do so gradually, so opportunities will not evaporate overnight.`
          }, {
            text: `And finally consider this: having cash now will enable you to buy what turned out to be the most valuable currency: the toilet paper...`
          }]
        }]
      }
    ]
  }
]

